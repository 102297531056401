import loadable from "@loadable/component";
import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FeedBlockValue, MeetTheShowPersonalityResolver } from "@ihr-radioedit/inferno-core";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { getThemingCssFromTags } from "../../services/Sites.utils";
import { Container, Heading, Carousel } from "../../ui";
import type { PageBlockInterface } from "../Block.component";
import "./MeetTheShow.style.scss";
import { isLeadsCard, Store } from "@inferno/renderer-shared-core";
import { isFeedBlock } from "@inferno/renderer-shared-core";

const PersonalityComponent = loadable(() => import("../show/Show.component"));

const log = ILog.logger("MeetTheShow");

interface MeetTheShowProps extends PageBlockInterface {
  store?: Store;
  blockValue: FeedBlockValue;
}

export const MeetTheShow = inject("store")(
  observer(({ store, block, blockValue }: MeetTheShowProps) => {
    // it would never get here if block.feed was falsy, since this component is rendered by
    // DatasourceLoader which does the check, but just to make TS happy
    if (!store || !isFeedBlock(block)) {
      return null;
    }

    // Call store block for tagging
    useEffect(() => {
      store.storeBlock(block);
    });

    if (!block.feed?.results.length) {
      return null;
    }

    const { t } = useTranslation();
    const classNames = classnames("component-show-collection", "meet-the-show", getThemingCssFromTags(block.tags), {
      small: block.tags?.includes("display-hints/small"),
    });

    return (
      <Container className={classNames}>
        <Heading level={2}>{blockValue.title || t("meet_the_show")}</Heading>
        <Carousel minSlidesToShow={3} maxSlidesToShow={6}>
          {block.feed.results.map((personality, i) => {
            if (!isLeadsCard(personality.record)) {
              return null;
            }

            const resolvedPersonality = MeetTheShowPersonalityResolver.decode(personality.record);
            if (isLeft(resolvedPersonality)) {
              const errorMessage =
                `Meet the Show Personality Failed Validation for ${personality.record.title}\n` +
                getPaths(resolvedPersonality).join("\n");
              log.info(errorMessage);
              return null;
            }
            return (
              <PersonalityComponent
                key={i}
                title={resolvedPersonality.right.title}
                thumb={resolvedPersonality.right.image}
                url={resolvedPersonality.right.link.urls.web}
              />
            );
          })}
        </Carousel>
      </Container>
    );
  }),
);

export default MeetTheShow;
